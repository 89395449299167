<template>
    <div class="secondary relative mt-10 bg-red-900">
        <div class="absolute bottom-0 h-80 w-full xl:inset-0 xl:h-full">
            <div v-editable="blok" class="h-full w-full xl:grid xl:grid-cols-2">
                <div class="h-full xl:relative xl:col-start-2">
                    <NuxtImg
                        v-if="blok.asset.filename"
                        :src="blok.asset.filename"
                        class="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                        :alt="blok.asset.alt"
                        provider="storyblok"
                        loading="lazy"
                        format="webp"
                    />
                    <div
                        aria-hidden="true"
                        class="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-red-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                    />
                </div>
            </div>
        </div>
        <div
            v-editable="blok"
            class="!text-white mx-auto max-w-4xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8"
        >
            <div
                class="relative pb-64 pt-12 sm:pb-64 sm:pt-24 xl:col-start-1 xl:pb-24"
            >
                <h2
                    class="!text-white text-sm font-semibold uppercase tracking-wide"
                >
                    {{ blok.callout }}
                </h2>
                <p class="mt-3 text-3xl font-extrabold">
                    {{ blok.title }}
                </p>
                <p class="mt-5 text-lg">
                    {{ blok.body }}
                </p>
                <div
                    v-if="facets.body_style"
                    class="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2"
                >
                    <DealerMeNuxtLink
                        v-for="(metric, index) in metrics"
                        :key="index"
                        :href="`/inventory?body_style=${metric.query}`"
                    >
                        <span class="!text-white block text-2xl font-bold">{{
                            metric.label
                        }}</span>
                        <span class="!text-white mt-1 block text-base"
                            ><span class="font-medium !text-white text-xl">{{
                                metric.count
                            }}</span>
                            {{ metric.rest }}</span
                        >
                    </DealerMeNuxtLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { useInventoryFilterStore } from '@/stores/inventoryFilter'

defineProps({
    blok: {
        type: Object as PropType<Record<string, any>>,
        required: true,
    },
})

const facetStore = useInventoryFilterStore()

const facets = computed(() => {
    return facetStore.facets
})

onMounted(() => {
    facetStore.fetchFacets()
})

function getMetricCounts(query: string) {
    let total = 0

    if (facets.value.body_style) {
        query.split(',').forEach((val: string) => {
            const findBodyStyle = facets.value.body_style.find(
                (item) => item.value === val,
            )

            total += findBodyStyle ? findBodyStyle.count : 0
        })
    }

    return total
}

const metrics = computed(() => {
    return [
        {
            query: 'Sedan,Coupe,Hatchback,Convertible',
            label: 'Cars',
            rest: 'on the lot right now.',
            count: getMetricCounts('Sedan,Coupe,Hatchback,Convertible'),
        },
        {
            query: 'SUV',
            label: 'SUVs',
            rest: 'for you and the family.',
            count: getMetricCounts('SUV'),
        },
        {
            query: 'Truck',
            label: 'Trucks',
            rest: 'for sale at our locations.',
            count: getMetricCounts('Truck'),
        },
    ]
})
</script>
